/** @jsx h */

import {h, render} from "preact";
import {components}  from 'react-select'
import AsyncSelect  from 'react-select/async'
import {onFind} from "@elements/init-modules-in-scope";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import throwError from "@elements/throw-error";
import {useState} from "preact/hooks";
import {translate} from '@elements/translations';
import axios from "axios";
import {addParamsToUrl} from "./helper";
import {addClass, removeClass, find} from '@elements/dom-utils';


const defaultSelectors = {
    base: '.js-typeahead-navbar',
    wrapper: ".js-typeahead-navbar__wrapper",
};

const defaultOptions = {
    isClearable: true,
    defaultValue: "",
    minLength: 2
};

export function init(options = defaultOptions, selectors = defaultSelectors) {

    onFind(selectors.base, function (baseElement) {
        let options = {
            ...defaultOptions,
            ...options,
            ...transformDataSetOptions(getPrefixedDataSet('typeahead', baseElement))
        };

        render(<TypeaheadConfig isClearable={options.isClearable}
                                placeholder={options.placeholder}
                                value={options.defaultValue}
                                defaultIsOpen={false}
                                name={options.name}
                                minLength={options.minLength}
                                groups={options.groups}
                                action={options.action}
                                defaultOptions={options.options}/>,
            baseElement);
    });
}

function TypeaheadConfig({
                             defaultOptions = [],
                             value = "",
                             placeholder = "",
                             defaultIsOpen = false,
                             name,
                             minLength = 0,
                             groups = [],
                             action = null,
                         }) {

    const [inputValue, setInputValue] = useState(value);
    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [totalCountText, setTotalCountText] = useState('');
    const [totalCountNumber, setTotalCountNumber] = useState('');


    const loadData = (stringValue, callback) => {

        const _config = window._config || {};
        if (!_config.typeaheadDataUrl) {
            console.warn(`no action is set`);
        }

        let data = {
            [name]: stringValue
        };
        let wrapper = find(defaultSelectors.wrapper);

        const url = addParamsToUrl(_config.typeaheadDataUrl, data);

       groups = JSON.parse(groups);

        axios({
            method: 'GET',
            url: url})
            .then(function (result) {
                const options = [];
                const teaser= [];
                let amount = 0;

                options.push({label: '', isPlaceholder: true});
                for (let i = 0; i < groups.length; i++) {
                    if (result.data['results'][groups[i].name]) {
                        let newGroup = {
                            label: groups[i].title,
                            overviewSearchName: groups[i].overviewSearchName ? groups[i].overviewSearchName : name,
                            overviewUrl: groups[i].overviewUrl,
                            overviewText: groups[i].overviewText,
                            options: result.data['results'][groups[i].name],
                            className: groups[i].className,
                        };

                        if ((groups[i].name === "images" || groups[i].name === "families") &&
                            result.data['results'][groups[i].name].length > 0) {
                            teaser.push(groups[i].name)
                        }
                        if (result.data['results'][groups[i].name].length > 0) {
                            amount ++;
                        }

                        options.push(newGroup);
                    }
                }

                if (teaser.length === 2 && amount > 4) {
                    addClass("react-select-wrapper__family", wrapper);
                }else {
                    removeClass("react-select-wrapper__family", wrapper);
                }

                setTotalCountText(result.data['totalCountText']);
                setTotalCountNumber(result.data['totalCountItem']);

               callback(options);
            })
            .catch(function (error) {
                console.error(`Could not load configuration data for typeahead`, error);
            });

    };

    const handleInputChange = (newValue) => {
        let input = newValue;

        setInputValue({ input });

        if(input.length >= minLength) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }

        return input;
    };

    const handleChange = (currentSelection) => {
        if (currentSelection.isPlaceholder) {
            handleSubmit();
        } else {
            window.location = currentSelection.url;
        }
    };

    const handleSubmit = (evt) => {
        if (evt) {
            evt.preventDefault();
        }
        window.location.href = addParamsToUrl(action, {[name]: encodeURIComponent(inputValue.input)});
    };

    const styling = {
        control: (styles) => ({ ...styles}),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#fff' : 'transparent',

            }
        },
        menu: styles => ({ ...styles, backgroundColor: '#fff'})
    };

    const [lastValue, setLastValue] = useState(value);

    return (
        <div className={"container content-block content-block-md "}>
        <form className={"navbar__search-form"}  onSubmit={(evt) => handleSubmit(evt)}>
            <AsyncSelect
                inputValue={inputValue.input}
                name={name}
                className="react-select-wrapper js-typeahead-navbar__wrapper"
                classNamePrefix="react-select"
                menuIsOpen={isOpen}
                loadOptions={isOpen ? loadData : ''}
                defaultOptions
                loadingMessage={() => translate('Suchergebnisse werden geladen...')}
                placeholder={placeholder}
                styles={styling}
                isClearable
                onInputChange={handleInputChange}
                onChange={handleChange}
                components={{ Group, GroupHeading, Option, DropdownIndicator}}
                valueBeforeClose={lastValue}
                onMenuClose={() => setLastValue(inputValue.input)}
            />
        </form>
            {
                inputValue.input != null && inputValue.input.length >= minLength &&
                <p className={"data__number-of-results"}>{totalCountText} <strong>{totalCountNumber}</strong>:</p>
            }
        </div>
    )
}

const DropdownIndicator = props => {
    const handleClick = () => {
    };
    const showX = props.selectProps.inputValue !== '';
    return (
        <components.DropdownIndicator {...props}>
            {showX ? (
                <button type={"button"} onClick={() => handleClick()} className={"control__clear-search btn btn-link"}><span className={"icon icon-close"}></span></button>
            ) : <span></span>}
        </components.DropdownIndicator>
    );
};

const Group = props => {
    let showAll
    let url

    if (props.data.label === "Gallery") {
        if (props.data.options[0].area === "images-text"){
            showAll = props.data.overviewText !== undefined;
            url = props.data.options[0].url
        }else{
            showAll = ''
        }
    }else{
        showAll = props.data.overviewText !== undefined;
        url = props.data.overviewUrl !== undefined ? addParamsToUrl(props.data.overviewUrl, {[props.data.overviewSearchName]: encodeURIComponent(props.selectProps.inputValue)}) : ''
    }

    return (
        <components.Group {...props} className={props.data.className}>
            {props.children}
            {showAll ? (
                <div className={"data-group__show-all stretch-link"}>
                    <div className={"data-group__link-text"}>
                        {props.data.overviewText}
                    </div>
                    <div className={"data-group__icon"}>
                        <a href={url} className={"stretch-link__link"}><span className={"icon icon-arrow-goto"}></span></a>
                    </div>
                </div>
            ) : null}
        </components.Group>
    );
};

const GroupHeading = props => {

    return (
        <components.GroupHeading {...props}>
            <div className="data-group__heading font-bold">
                {props.children}
            </div>
        </components.GroupHeading>
    );
};

const Option = props => {
    let isPlaceholder = props.data.isPlaceholder;
    const area = props.data.area;

    return (
        <components.Option {...props} className={isPlaceholder ? 'react-select__option--placeholder' : ''}>
            {!isPlaceholder ? (
                <div className="stretch-link">

                    {(() => {
                        switch (area) {
                            case "products":
                                return (
                                    <div className={"typeahead__item typeahead__item--products"}>
                                        <div className="row gx-4">
                                            <div className="col-3">
                                                <div className="ratio ratio-1x1 typeahead__item-img">
                                                    <img src={props.data.imgUrl} alt="" className={"ratio-item"}/>
                                                </div>
                                            </div>
                                            <div className="col-9 my-auto">
                                                <div className={"typeahead__item-title font-bold"}>
                                                    <a href={props.data.url} className="stretch-link__link">
                                                        {props.data.dataTitle}
                                                    </a>
                                                </div>
                                                <div className={"typeahead__item-description"}>
                                                    {props.data.dataDescription}
                                                </div>
                                                {props.data.dataItemNumber ? (
                                                    <div className={"mt-1"}>
                                                        {props.data.dataItemNumber}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                )
                                break;
                            case "images":
                                return (
                                    <div className={"typeahead__item typeahead__item--images"}>

                                        <section className="img-teaser stretch-link">
                                            <div
                                                className="ratio ratio-1x1">
                                                <img src={props.data.imgUrl} alt="" className={"img-teaser__img ratio-item"}/>
                                            </div>
                                            <div className="img-teaser__content d-flex flex-column justify-content-end">
                                                <h3 className="img-teaser__content-title mb-0">
                                                    {props.data.dataTitle}
                                                </h3>
                                                <div className="mt-4 d-none d-md-block">
                                                    <a href={props.data.url} className="btn btn-sm btn-outline-light stretch-link__link">{props.data.dataBtn}</a>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                )
                                break;
                            case "images-text":
                                return (
                                    <div className={"typeahead__item typeahead__item--images"}>
                                        <div className={"typeahead__item-title"}>
                                            {props.data.dataTitle}
                                        </div>
                                    </div>
                                )
                            case "family":
                                return (
                                    <div className={"typeahead__item typeahead__item--family"}>

                                        <section className="img-teaser stretch-link">
                                            <div
                                                className="ratio ratio-1x1">
                                                <img src={props.data.familyImage} alt="" className={"img-teaser__img ratio-item"}/>
                                            </div>
                                            <div className="img-teaser__content d-flex flex-column justify-content-end">
                                                <h3 className="img-teaser__content-title mb-0">
                                                    {props.data.familyTitle}
                                                </h3>
                                                <h4 className="img-teaser__content-subtitle">
                                                    {props.data.familySubtitle}
                                                </h4>
                                                <div className="mt-4 d-none d-md-block">
                                                    <a href={props.data.familyUrl}
                                                       className="btn btn-sm btn-outline-light stretch-link__link">{props.data.familyBtn}</a>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                )
                                break;
                            case "categories":
                                return (
                                    <div className={"typeahead__item typeahead__item--categories"}>
                                        <div className={"typeahead__item-title"}>
                                            <a href={props.data.url} className="stretch-link__link">
                                                {props.data.dataTitle}
                                            </a>
                                        </div>
                                    </div>
                                )
                                break;
                            case "archiveProducts":
                                return (
                                    <div className={"typeahead__item typeahead__item--info"}>
                                        <div className={"typeahead__item-title"}>
                                            {translate('search.archive-products.desc')}
                                        </div>
                                    </div>
                                )
                                break;
                                default:
                                    return (
                                        <div className={"typeahead__item typeahead__item--articles"}>
                                            <div className={"typeahead__item-title font-bold"}>
                                                <a href={props.data.url} className="stretch-link__link">
                                                    {props.data.dataTitle}
                                                </a>
                                            </div>
                                            <div className={"typeahead__item-description"}>
                                                {props.data.dataDescription}
                                            </div>
                                        </div>
                                    )
                                break;
                        }
                    })()}
                </div>
            ) : null }
        </components.Option>
    );
};

function transformDataSetOptions(options = {}) {
    let transformedOptions = {...options};

    if (transformedOptions.options) {
        try {
            transformedOptions.options = JSON.parse(transformedOptions.options)
        } catch (e) {
            transformedOptions.options = null;
            throwError(OPTIONS_ERROR_MESSAGE);
        }
    }
    return transformedOptions;
}

const OPTIONS_ERROR_MESSAGE = `Typeahead error: data-typeahead-options has to be a a valid JSON object. Most likely you used single quotes instead of double quotes for the JSON fields.`;