import {onFind} from "@elements/init-modules-in-scope";
import {on, addClass, removeClass, findAllIn} from "@elements/dom-utils";

export function init(){
    const isMobile = () => matchMedia('(max-width: 767px)').matches;


   onFind('.js-console', function (element){
       let btnsOpenDetail = findAllIn('.js-console__item-toggle', element),
           itemsDetail = findAllIn('.js-console__item-detail', element),
           btnsCloseDetail = findAllIn('.js-console__item-close-toggle', element),
           btnsOpenLinkDetail = findAllIn('.js-console__btn', element);

       btnsOpenDetail.map((btn, index) => {
           on("click", () => {
               if(isMobile()) {
                   removeClass("is-open",element);
                   addClass("is-active", itemsDetail[index]);
               }
           }, btn);
       });
       btnsCloseDetail.map((btn, index) => {
           on("click", () => {
               if(isMobile()) {
                   addClass("is-open",element);
                   removeClass("is-active", itemsDetail[index]);
               }
           }, btn);
       });
       btnsOpenLinkDetail.map((btn, index) => {
           on("click", () => {
               if(isMobile()) {
                   addClass("is-open",element);
                   removeClass("is-active", itemsDetail[index]);
               }
           }, btn);
       });
   })
}