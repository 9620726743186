import {onFind} from "@elements/init-modules-in-scope";
import Modal from 'bootstrap/js/dist/modal';

export function init () {
    onFind('.js-info-modal', function (modal) {
        let hiddenModals = sessionStorage.getItem('infoModalHidden') ? JSON.parse(sessionStorage.getItem('infoModalHidden')) : [];
        let modalInstance = new Modal(modal);

        let isHidden = hiddenModals.find((id) => id === modal.getAttribute('data-id'));
        if (!isHidden) {
            modalInstance.show();

            hiddenModals.push(modal.getAttribute('data-id'));
            sessionStorage.setItem('infoModalHidden', JSON.stringify(hiddenModals));
        }
    });
}
