import {onFind} from "@elements/init-modules-in-scope";
import {on, findAllIn, closest, removeAttribute, setAttribute} from '@elements/dom-utils';
import * as formValidation from '@elements/form-validation';

export function init () {
    onFind('.js-form-validation__checkbox-group', (checkboxGroup) => {
        let form = closest('.js-form-validation', checkboxGroup);

        if (form) {
            formValidation.getApi(form).then((formApi) => {
                let formInstance = formApi.getFormInstance();

                on('change', (evt) => {
                    let isChecked = findAllIn('input[type="checkbox"]:checked', checkboxGroup).length > 0;
                    findAllIn('input[type="checkbox"]', checkboxGroup).map((checkbox) => {
                        if (isChecked) {
                            removeAttribute('required', checkbox);
                            formInstance.updateFieldStatus(checkbox.getAttribute('name'), 'Valid');
                        } else {
                            setAttribute('required', 'required', checkbox);
                            formInstance.updateFieldStatus(checkbox.getAttribute('name'), 'NotValidated');
                        }
                    });
                }, checkboxGroup);
            });
        }
    })
}
