import {removeClass, trigger} from "@elements/dom-utils";

export function bodyClickHandler (target, className, triggerEvent) {
    window.addEventListener("click", function _listener(evt) {
        if (!target.contains(evt.target)) {
            removeClass(className, target);

            window.removeEventListener("click", _listener, true);
            trigger(triggerEvent, target);
        }
    }, true);
}

export const throttle = (fn, wait)  => {
    let inThrottle, lastFn, lastTime;
    return function() {
        const context = this,
            args = arguments;
        if (!inThrottle) {
            fn.apply(context, args);
            lastTime = Date.now();
            inThrottle = true;
        } else {
            clearTimeout(lastFn);
            lastFn = setTimeout(function() {
                if (Date.now() - lastTime >= wait) {
                    fn.apply(context, args);
                    lastTime = Date.now();
                }
            }, Math.max(wait - (Date.now() - lastTime), 0));
        }
    }
}

export const debounce = (func, wait, immediate)=> {
    let timeout;

    return function executedFunction() {
        let context = this;
        let args = arguments;

        let later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        let callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
};

export const addParamsToUrl = (url, params) =>
    url + (url.indexOf('?') >= 0 ? '&': '?')
    + Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');