"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, addClass, removeClass, findIn} from "@elements/dom-utils";

export function init () {

    onFind('.js-video', function(videoContainer){
        let video = findIn('.js-video__video', videoContainer),
            playButton = findIn('.js-video__play', videoContainer),
            posterElement = findIn('.js-video__poster', videoContainer);

        videoContainer.addEventListener("contextmenu", e => e.preventDefault());

        if (videoContainer.getAttribute('data-video-url')) {
            const loadVideo = () => {
                video.src = videoContainer.getAttribute('data-video-url');

                video.onload = function (){
                    addClass('is-playing', videoContainer);
                }
            };

            if(playButton){
                on('click', () => loadVideo(), playButton);
            }

            if (posterElement) {
                on('click', () => loadVideo(), posterElement);
            }
        } else {
            const playVideo = () => {
                video.play();
            };

            on('playing', function () {
                if (video.getAttribute('data-autoplay') !== 'true') {
                    trackVideoProgress(video, 25);
                    trackVideoProgress(video, 50);
                    trackVideoProgress(video, 75);
                    trackVideoProgress(video, 90);

                    addClass('is-playing', videoContainer);

                    window.dataLayer.push({
                        "event": "video",
                        "type": "play",
                        "name": video.getAttribute('data-event-title') ? video.getAttribute('data-event-title') : ''
                    });
                }

            }, video);

            on('pause', function () {
                // Prevent pause event from firing when video ends
                if (video.currentTime === video.duration) {
                    return
                }

                window.dataLayer.push({
                    "event": "video",
                    "type": "pause",
                    "name": video.getAttribute('data-event-title') ? video.getAttribute('data-event-title') : ''
                });
            }, video);

            on('ended', function () {
                removeClass('is-playing', videoContainer);

                if (video.getAttribute('data-autoplay') !== 'true') {
                    window.dataLayer.push({
                        "event": "video",
                        "type": "ended",
                        "name": video.getAttribute('data-event-title') ? video.getAttribute('data-event-title') : ''
                    });
                }
            }, video);

            if(playButton){
                on('click', () => playVideo(), playButton);
            }
            if (posterElement) {
                on('click', () => playVideo(), posterElement);
            }
        }
    });
}

export const trackVideoProgress = (video, progress, title) => {
    let total= video.duration;

    let t = setTimeout(() => {
        window.dataLayer.push({
            "event": "video",
            "type": "progress",
            "value": progress,
            "name": title ? title : video.getAttribute('data-event-title') ? video.getAttribute('data-event-title') : ''
        });
        clearTimeout(t);
    }, (total/100*progress)*1000);
}