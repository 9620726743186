"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, addClass, removeClass, find, findIn, closest, removeAttribute, setAttribute, findAllIn} from "@elements/dom-utils";
import {activateScrollLock, deactivateScrollLock} from "@elements/scroll-lock";
import asyncAppend from "@elements/async-append";
import axios from "axios";

let isOpen;
let currentScrollPosition = 0;
let lastChangedCollapse; //collapse which is changed last
let offsetOfLastChangedCollapse = 0;

export function init () {
    onFind('.js-overlay', function (overlayElement) {
        let isInFilter = closest('.js-product-grid', overlayElement) || closest('.js-ajax-form', overlayElement),
            keepOpen = overlayElement.getAttribute('data-overlay-keep-open'),
            scrollContent = findIn('.js-overlay__scroll-body', overlayElement);

        if (keepOpen && isOpen === overlayElement.getAttribute('id')) {
            addClass('no-transition', overlayElement);
            addClass('is-open', overlayElement);
        } else if (!keepOpen && isOpen === overlayElement.getAttribute('id')) {
            if (matchMedia('(max-width: 767px)').matches) {
                deactivateScrollLock();
            }
        }

        if (isInFilter) {

            if (scrollContent) {
                on('scroll', function (evt) {
                    currentScrollPosition = evt.target.scrollTop;
                }, scrollContent);
            }

            on('change', function (evt) {
                let container = closest('.js-filter-box-group', evt.target);

                    if (container) {
                        let collapseWrapper = findIn('.js-filter-group__dropdown', container),
                            collapseId = collapseWrapper.getAttribute('id');

                        lastChangedCollapse = `#${collapseId}`;
                        offsetOfLastChangedCollapse = container.getBoundingClientRect().top;
                    }
            }, overlayElement);


            if (closest('.js-product-grid', overlayElement)) {
                if (!keepOpen) {
                    findAllIn('.js-product-grid__link', overlayElement).map((link) => {
                        on('click', function () {
                            removeClass('is-open', overlayElement);
                            deactivateScrollLock();
                        }, link)
                    });
                }

                if (findIn('.js-overlay__loading', overlayElement)) {
                    on('product-grid.changed', function () {
                        removeAttribute('hidden', findIn('.js-overlay__loading', overlayElement))
                    }, closest('.js-product-grid', overlayElement));
                }

                if (scrollContent) {
                    on('product-grid.success', function () {
                        let lastChangedCollapseTemp = findIn(lastChangedCollapse, scrollContent);
                        let newOffset = (typeof lastChangedCollapse === 'undefined') ? 0 : lastChangedCollapseTemp.getBoundingClientRect().top - offsetOfLastChangedCollapse;

                        if (lastChangedCollapseTemp) {
                            scrollContent.scrollTop = currentScrollPosition + (newOffset - currentScrollPosition)
                        } else {
                            scrollContent.scrollTop = currentScrollPosition
                        }
                    }, closest('.js-product-grid', overlayElement));
                }
            } else if (closest('.js-ajax-form', overlayElement)) {
                on('submit.ajax-form', function () {
                    removeAttribute('hidden', findIn('.js-overlay__loading', overlayElement))
                }, closest('.js-ajax-form', overlayElement));

                on('success.ajax-form', function () {
                    setAttribute('hidden', true, findIn('.js-overlay__loading', overlayElement))
                }, closest('.js-ajax-form', overlayElement));

                if (scrollContent) {
                    on('success.ajax-form', function () {
                        scrollContent.scrollTop = currentScrollPosition
                    }, closest('.js-ajax-form', overlayElement));
                }
            }
        }
    });

    onFind('.js-overlay__toggle', function (item) {
        on('click', function () {
            let target = find(item.getAttribute('data-target'));
            toggleOverlay(target);

            if (item.getAttribute('data-content-url')) {
                loadOverlayContent(target, item.getAttribute('data-content-url'));
            }
        }, item);
    });

    onFind('.js-overlay__close', function (closeButton) {
        on('click', function () {
            toggleOverlay(closest('.js-overlay', closeButton));
        }, closeButton);
    });
}

function toggleOverlay(target) {
    if (hasClass('is-open', target)) {
        removeClass('is-open', target);

        if (matchMedia('(max-width: 767px)').matches) {
            deactivateScrollLock();
        }
        isOpen = false;
    } else {
        addClass('is-open', target);
        if (matchMedia('(max-width: 767px)').matches) {
            activateScrollLock();
        }
        isOpen = target.getAttribute('id');

        window.addEventListener("click", function _listener(evt) {
            if (!target.contains(evt.target)) {
                removeClass('is-open', target);

                window.removeEventListener("click", _listener, true);
            }
        }, true);
    }
}

export function loadOverlayContent(modal, url) {
    let pendingRequest = axios({method: 'GET', url: url });

    asyncAppend({
        target: findAllIn('.js-overlay__content', modal),
        loading: findAllIn('.js-overlay__loading', modal)
    }, pendingRequest.then((x) => x.data));

    return pendingRequest;
}