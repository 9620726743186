import {onFind} from "@elements/init-modules-in-scope";
import {on} from "@elements/dom-utils";

export function init() {
    onFind('.js-live-chat', function (button) {
        on('click', function () {
            if (OnetrustActiveGroups.includes('C0003')) {
                LiveChatWidget.call("maximize");
            } else {
                OneTrust.ToggleInfoDisplay();
            }
        }, button);
    });
}