import {onFind} from "@elements/init-modules-in-scope";
import {findIn} from "@elements/dom-utils";
import {onEnterViewPort} from "@elements/viewport-utils";

export function init() {
    onFind('.js-viewport-video', function (hoverVideo) {
        onEnterViewPort(hoverVideo, function (renderItem) {
            let template = findIn('.js-viewport-video__template', renderItem);
            if (template) {
                renderItem.innerHTML = template.innerHTML;
            }
        }, { offset: 200 });
    });
}