"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, addClass, removeClass, closest, findIn, findAllIn, find} from "@elements/dom-utils";
import {activateScrollLock, deactivateScrollLock} from "@elements/scroll-lock";

export function init () {
    const isMobile = () => matchMedia('(max-width: 767px)').matches;

    onFind('.js-nav__toggle', function (toggle) {
        let nav = closest('.js-nav-container', toggle);

        on('click', function () {
            if (hasClass('is-open', nav)) {
                removeClass('is-open', nav);
                deactivateScrollLock();
            } else {
                addClass('is-open', nav);
                activateScrollLock();
            }
        }, toggle);
    });

    onFind('.js-nav__list-toggle', function (toggleButton) {
        let list = closest('.js-nav__list', toggleButton);
        let listItems = findAllIn('.js-nav__list-item', list);

        on('click', function () {
            let parentItem = closest('.js-nav__list-item', toggleButton);
            let mainNav = closest('.js-nav-container', parentItem);

            if (hasClass('is-open', toggleButton)) {
                removeClass('is-open', parentItem);
                removeClass('is-open', toggleButton);

                if (!isMobile()) {
                    removeClass('is-open', mainNav);
                }
            } else {
                if (isMobile()) {
                    addClass('is-open', parentItem);
                    addClass('is-open', toggleButton);

                    if (closest('.main-nav ', parentItem)) {
                        closest('.main-nav ', parentItem).scrollTop = 0;
                        addClass('is-locked', closest('.main-nav ', parentItem));
                    }
                } else {
                    addClass('is-open', mainNav);
                    listItems.forEach(function (item) {
                        removeClass('is-open', item)
                    });
                    addClass('is-open', parentItem);
                    addClass('is-open', toggleButton);
                }

                if (!isMobile() || hasClass('js-nav__list-toggle-search', toggleButton)) {
                    window.addEventListener("click", function _listener(evt) {
                        if (!parentItem.contains(evt.target)) {
                            removeClass('is-open', parentItem);
                            removeClass('is-open', toggleButton);

                            if (!isMobile()) {
                                if(!findIn('.is-open', mainNav)) {
                                    removeClass('is-open', mainNav);
                                }
                            }

                            window.removeEventListener("click", _listener, true);
                        }
                    }, true);
                }

                if (hasClass('js-nav__list-toggle-search', toggleButton)) {
                    findIn('.js-typeahead-navbar input', parentItem).focus();
                }
            }
        }, toggleButton);
    });

    onFind('.js-nav__toggle-list', function (toggleButton) {
        let target = find(toggleButton.getAttribute('data-target'));

        on('click', function () {
            if (hasClass('is-open', target)) {
                removeClass('is-open', target);
            } else {
                addClass('is-open', target);
            }
        }, toggleButton);
    });

    onFind('.js-nav__list-back', function (backButton) {
        on('click', function () {
            let item = closest('.js-nav__list-item', backButton);
            removeClass('is-open', item)

            if (closest('.main-nav ', item)) {
                removeClass('is-locked', closest('.main-nav ', item));
            }
        }, backButton)
    });
}