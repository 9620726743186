import {onFind} from "@elements/init-modules-in-scope";
import {findIn} from '@elements/dom-utils';

import Toast from 'bootstrap/js/dist/toast';
import {init} from '@elements/alert-notification';
init({
    key: '__alertNotification',
    renderFunction: function renderFunction({type, title, content, closable = true, styleModifier = '', placement, defaultRender, autohide = true} = {}, options) {
        if (content || title) {
            let containers = options.container;

            containers.map(function(container) {
                container.removeAttribute('hidden');
                container.insertAdjacentHTML('beforeend', (
                    `<div class="toast toast--${type} ${styleModifier}" role="alert" data-bs-autohide="${autohide}" >
                        ${closable ? (
                        `<button type="button" 
                             class="toast__close" 
                             data-bs-dismiss="toast" 
                             aria-label="${options.closeText}"  
                             title="${options.closeText}">
                             <span class="icon icon-close"></span>
                         </button>`
                    ) : ''} 

                    ${title ? (
                        `<div class="toast-header h4 mb-0 d-flex">
                        ${title}               
                    </div>`
                    ) : ''}
                    
                ${content ? (
                        `<div class="toast-body">
                        ${content}               
                    </div>`
                    ) : ''}
            </div>`));

                new Toast(findIn('.toast', container)).show();
            });
        }
    }
});

import * as consentOverlay from '@elements/consent-overlay';
consentOverlay.init(
    {
        cmpTool: 'onetrust',
        revalidateEvent: null
    },
    {
        base: '.js-consent',
        template: '.js-consent__template',
        dataset: 'consent',
        button: '.js-consent__accept-btn'
    });

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as smoothScroll from "@elements/smooth-scroll";
smoothScroll.init({scrollOffset: matchMedia('(max-width: 767px)').matches ? 70 : 112 });

import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-bs-toggle="collapse"]',function (element) {
    if (element.getAttribute('data-collapse-xs')) {
        if (matchMedia('(max-width: 767px)').matches) {
            new Collapse(element.getAttribute('data-bs-target'), {
                toggle: false
            })
        }
    } else {
        new Collapse(element.getAttribute('data-bs-target'), {
            toggle: false
        });
    }
});


import Modal from 'bootstrap/js/dist/modal';
onFind('[data-bs-toggle="modal"]',function (element) {
    new Modal(element);
});

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as contentVisibility from './content-visibility';
contentVisibility.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as nav from './nav';
nav.init();

import * as sideConsole from './console';
sideConsole.init();

import * as overlay from './overlay';
overlay.init();

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

// import * as fadeIn from './fade-in';
// fadeIn.init();

import * as typeaheadNav from './typeahead-navbar';
typeaheadNav.init();

import * as formValidation from '@elements/form-validation';
formValidation.init();

import * as formValidationExtend from './form-validation-extend';
formValidationExtend.init();

import * as hashCash from './hash-cash';
hashCash.init();

import * as viewportVideo from './viewport-video';
viewportVideo.init();

import * as liveChat from './live-chat';
liveChat.init();

import * as tracking from "@elements/tracking";
tracking.initGtmEventTracking({debug: true});

import * as trackingHelper from './tracking-helper';
trackingHelper.init();

import * as infoModal from './info-modal';
infoModal.init();

import * as video from './video';
video.init();