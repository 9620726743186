import {on} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('[data-event-tracking-location-select]', function (select) {
        on('change', function (evt) {
            window.dataLayer.push({
                'event': 'search_country',
                'search_value' : evt.target.value,
            });
        }, select);
    });
}